/* global setImmediate */
import { SB_Input } from "./ui_elements.es6";

export function SB_Timestamp() {return parseInt(new Date().getTime().toString().substring(0,10));}
export function SB_Microtime() {return new Date().getTime() / 1000;}
export function SB_UrlEncode(str) {return encodeURIComponent(str); }
export function SB_ReadNumber(num) {return Number(num.replace(/\./g,'').replace(',','.'));}
export function SB_FormatNumber(num) {return __number_format(num,2,',','.');}
export function SB_CalcField(str, decimals) {
	try{
		var calced = eval( str.replace(/\./gi,"").replace(/,/gi,".").replace(/x/gi,"*") );
		if (decimals == "avoid") {
			return SB_FormatNumberAvoidDecimals(calced);
		} else {
			return SB_FormatNumber(calced);
		}
	} catch(e) {
		return "";
	}
}
export function SB_FormatNumberAvoidDecimals(num) {
	if (num == Math.round(num)) {
		return __number_format(num, 0, ",", ".");
	} else {
		return SB_FormatNumber(num);
	}
}
export function SB_FormatTime(seconds) {
	var hours = Math.floor(seconds/3600);
	var mins = Math.floor((seconds%3600)/60);
	if (hours > 0 && mins < 10)
		mins = "0" + mins;

	if (hours > 0)	return hours +":"+ mins + " timer";
	else			return mins + " min";
}
export function SB_FormatShortTime(seconds) {
	var hours = `00${Math.floor(seconds/3600)}`.substr(-2);
	var mins = `00${Math.floor((seconds%3600)/60)}`.substr(-2);
	return `${hours}:${mins}`;
}
export function SB_FloatUnitCountToTime(unit_count, unit_type) {
	if (unit_type == 't') {
		return SB_FormatShortTime(Math.round(unit_count * 60) * 60);
	} else if (unit_type == 'min') {
		return SB_FormatShortTime(Math.round(unit_count * 60));
	}
	return unit_count;
}
export function SB_TimeToFloatUnitCount(unit_count, unit_type) {
	if (`${unit_count}`.trim().match(/^\d+$/)) {
		return SB_ReadNumber(`${unit_count}`.trim());
	}
	let hour_value = `${unit_count}`.trim().match(/^(?:(\d+)(:|timer|t))? *(?:(\d+)(minutter|min|m)?)?$/i);
	if (hour_value) {
		if (typeof hour_value[1] === "undefined") {
			hour_value[1] = 0;
		}
		if (typeof hour_value[3] === "undefined") {
			hour_value[3] = 0;
		}
		if (unit_type == "t") {
			unit_count = parseInt(hour_value[1]) + Math.round(hour_value[3] / 0.6) / 100;
		} else if (unit_type == "min") {
			unit_count = parseInt(hour_value[1]) * 60 + parseInt(hour_value[3]);
		}
	} else {
		unit_count = SB_ReadNumber( unit_count );
	}
	return unit_count;

}

export function SB_FormatDate(timestamp)
{
	var d = new Date(timestamp*1000);
	var date = d.getDate()+"";
	while (date.length < 2)
	{
		date = "0"+date;
	}
	var month = (d.getMonth()+1)+"";
	while (month.length < 2)
		month = "0"+month;
	var year = d.getFullYear()+"";
	while (year.length < 2)
		year = "0"+year;

	return date + "/" + month + "-" + year;
}
export function SB_DateToTimestamp(date,format)
{
	format = format || "DMY";
	if (date.toLowerCase() == "d") {
		return +(new Date()).getTime().toString().substring(0,10);
	}
	if (date.match(/\d{4}\D\d{1,2}\D\d{1,2}/)) {
		format = 'YMD';
	} else if (date.match(/\d{1,2}\D\d{1,2}\D\d{4}/)) {
		format = 'DMY';
	}
		
	var arr = date.split(/[\D]+/).filter(function(val){return val});
	
	if (arr.length == 1)
	{
		if (date.length <= 2) {
			arr = [date];
			format = format.replace(/[YM]/g,"");
		} else if (date.length == 4) {
			arr = [
				date.substring(0,2),
				date.substring(2,4),
			]
			format = format.replace(/[Y]/,"");			
		} else if (date.length == 6) {
			arr = [
				date.substring(0,2),
				date.substring(2,4),
				date.substring(4,6)
			];
		} else if (date.length == 8) {
			if (date.substring(4,6) > 12) {
				format = "DMY";
			} else {
				format = "YMD";
			}
			if (format.indexOf("Y") == 0)
			{
				arr = [
					date.substring(0,4),
					date.substring(4,6),
					date.substring(6,8)
				];
			}
			else if (format.indexOf("Y") == 1)
			{
				arr = [
					date.substring(0,2),
					date.substring(2,6),
					date.substring(6,8)
				];
			}
			else if (format.indexOf("Y") == 2)
			{
				arr = [
					date.substring(0,2),
					date.substring(2,4),
					date.substring(4,8)
				];
			}
		}
	} else if (arr.length == 2) {
		format = format.replace(/[Y]/,"");			
	}
	
	
//	if (arr.length == format.length && format.indexOf("D")!==-1 && format.indexOf("M")!==-1 && format.indexOf("Y")!==-1)
	if (arr.length == format.length && format.indexOf("D")!==-1)
	{
		var d = arr[format.indexOf("D")];
		var m = format.indexOf("M") !==-1 ? arr[format.indexOf("M")] : (new Date()).getMonth()+1;
		var y = format.indexOf("Y") !==-1 ? arr[format.indexOf("Y")] : (new Date()).getFullYear();
		
		if (y < 100)
			y = (+y) + 2000;

		if ( (d+"").length > 2) d = d.substring( (d+"").length -2 );
		if ( (m+"").length > 2) m = m.substring( (m+"").length -2 );
		if ( (y+"").length > 4) y = y.substring( (y+"").length -4 );

		var r = 0;
		if ( !isNaN((d-0)) && !isNaN((m-0)) && !isNaN((y-0)) )
		{
			d=("0"+d).substr(-2);
			m=("0"+m).substr(-2);
			var tmp = new Date(y+"-"+m+"-"+d);
			r = tmp.getTime()/1000-0;
		}
		if (r < 10) return false;

		return r;
	}

	return false;

}

export function parse_time(input) {
	let h = false, m = false, s = false;
	let parts = input.split(/[:. ]/);
	if (parts.length == 3) {
		h = clean(parts[0]);
		m = clean(parts[1]);
		s = clean(parts[2]);
	} else if (parts.length == 2) {
		parts.forEach(part => {
			if (part.match(/[^A-Za-z]t/)) {
				h = clean(part);
			} else if (part.match(/[^A-Za-z]m/)) {
				m = clean(part);
			} else if (part.match(/[^A-Za-z]s/)) {
				s = clean(part);
			} else {
				if (s !== false) {
					s = false;
				} else if (m !== false) {
					s = clean(part);
				} else if (h !== false) {
					m = clean(part);
				} else if (h === false) {
					h = clean(part);
				}
			}
		})
	} else if (parts.length == 1) {
		let part = parts[0];
		if (part.match(/[^A-Za-z]t/)) {
			h = clean(part);
		} else if (part.match(/[^A-Za-z]m/)) {
			m = clean(part);
		} else if (part.match(/[^A-Za-z]s/)) {
			s = clean(part);
		} else {
			m = clean(part);
		}
	}

	h = (h===false)?0:h;
	m = (m===false)?0:m;
	s = (s===false)?0:s;

	if (h%1) {
		m += (h%1)*60;
		h -= h%1;
	}
	if (m%1) {
		s += (m%1)*60;
		m -= m%1;
	}
	if (s%1) {
		s = Math.round(s);
	}
	
	return {
		h,
		m,
		s
	};
	
	function clean(str) {
		return +str.replace(/[^0-9,]/g, "").replace(",", ".");
	}
}

export function TimeToSeconds(input) {
	let {h, m, s} = parse_time(input);
	return h*60*60 + m*60 + s;
}



export function format_deal_time(time, type, range) {
	if (type == "precise") {
		return `kl. ${SB_FormatShortTime(TimeToSeconds(time))}`;
	} else if (type == "timeofday") {
		let secs = TimeToSeconds(time);
		if (secs > 18000 && secs <= 28800) { // 5:00 - 8:00
			return `morgen`;
		} else if (secs > 28800 && secs <= 43200) { // 8:00 - 12:00
			return `formiddag`;
		} else if (secs > 43200 && secs <= 64800) { // 12:00 - 18:00
			return `eftermiddag`;
		} else if (secs > 64800 && secs <= 82800) { // 18:00 - 23:00
			return `aften`;
		} else {
			return `nat`;
		}
	} else if (type == "approximately") {
		range = TimeToSeconds(range);
		if (!range) {
			return `ca. ${SB_FormatShortTime(TimeToSeconds(time))}`;
		} else {
			let time_secs = TimeToSeconds(time);
			return `mellem ${SB_FormatShortTime(time_secs - (range/2))} og ${SB_FormatShortTime(time_secs + (range/2))}`;
		}
	} else if (type == "before") {
		range = TimeToSeconds(range);
		if (!range) {
			return `før ${SB_FormatShortTime(TimeToSeconds(time))}`;
		} else {
			let time_secs = TimeToSeconds(time);
			return `mellem ${SB_FormatShortTime(time_secs - range)} og ${SB_FormatShortTime(time_secs)}`;
		}
	} else if (type == "after") {
		range = TimeToSeconds(range);
		if (!range) {
			return `efter ${SB_FormatShortTime(TimeToSeconds(time))}`;
		} else {
			let time_secs = TimeToSeconds(time);
			return `mellem ${SB_FormatShortTime(time_secs)} og ${SB_FormatShortTime(time_secs + range)}`;
		}
	} else {
		return `Ukendt interval-type [${type}]`;
	}
}

export function SB_WindowOpen(url)	{window.open(url);}
export function SB_WindowOpenAsPost(url, target)	{
	var parser = document.createElement('a');
	parser.href = url;
	var action = url.split("?")[0];

	target = target ? target : "_blank";

	var form = new Element("form").writeAttribute({
		"action": action,
		"target": target,
		"method": "post"
	});

	var query = url.toQueryParams();
	$H(query).each(function(pair){
		form.insert(new Element("input",{"name":pair.key, "value": pair.value, "type":"hidden"}));
	});
	$(document.body).insert({
		bottom: form
	});

	form.submit();
	form.remove();
}
export function SB_WindowRedirect(url)	{window.location = url;}
export function SB_WindowReload() {window.location.reload();}
export function SB_OpenRoute(printed, action) {
	var url = "pdf_route.php?printed="+printed+"&action="+action;
	
	if ($("input[RoutePrices[provision]]"))
	{
		var provision = ($("input[RoutePrices[provision]]").checked) ? 1 : 0;
		var revenue = ($("input[RoutePrices[revenue]]").checked) ? 1 : 0;
		var portrait = ($("input[RoutePrices[portrait]]").checked) ? 1 : 0;
		
		url = url + "&RoutePrices[provision]="+provision+"&RoutePrices[revenue]="+revenue+"&RoutePrices[portrait]="+portrait;
	}
	
	SB_WindowOpen(url +"&t="+SB_Timestamp());
}
export function SB_OpenInvoice(invoice_id) {SB_WindowOpen("pdf_invoice.php?invoice_id="+invoice_id+"&t="+SB_Timestamp());}
export function SB_OpenProvision(recorded) {SB_WindowOpen("pdf_provision.php?recorded="+recorded+"&t="+SB_Timestamp());}
export function SB_OpenOffer(offer_id) {SB_WindowOpen("pdf_offer.php?id="+offer_id+"&t="+SB_Timestamp());}
export function SB_OpenRecorded(recorded) {SB_WindowOpen("pdf_financial_recorded.php?recorded="+recorded+"&t="+SB_Timestamp());}
export function SB_OpenPbsExport(timestamp) {SB_WindowOpen("pbs_export.php?timestamp="+timestamp+"&t="+SB_Timestamp());}
export var SB_Globals = {};
export function SB_UniqueId() {
	if (typeof(SB_Globals.UniqueId) == "undefined") 
		SB_Globals.UniqueId = 0;
	SB_Globals.UniqueId+=1;
	return SB_Globals.UniqueId;
}
export var SB_DomLoaded = false;
export function SB_Onload(fnc) {
	if (SB_DomLoaded === false)
		document.observe('dom:loaded',function() {fnc();});
	else
		setImmediate(fnc);
}
SB_Onload(function() {SB_DomLoaded = true;});

export function SB_RemoveFocus() {
	let el = jQuery(":focus");
	if (el.length) {
		el[0].blur();
	}
	// $(document.body).insert({'bottom' : '<input type="text" id="focus-field" style="opacity: 0; position: fixed; top: 0;" />'});
	// $('focus-field').focus();
	// $('focus-field').remove();

}

export var Debug = function(str)
{
	if (typeof(str) == "object")
		str = DumpObject(str);
	$('JsDebug').show().insert({'top' : String(str + "<br />")});
}

export function SB_DetectPopupBlocker() {
	var PUtest = window.open("about:blank","","directories=no,height=100,width=100,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,top=0,location=no");
	if (PUtest && PUtest.outerHeight !== 0)
	{
		PUtest.close();
		return false;
	}
	else
	{
		return true;
	}
}

export function GetLast(array)
{
	return array[array.length-1];
}

export var DumpObject = function(obj)
{
	var r = '';
	for (let key in obj)
	{
		r = r + "["+key+"] = "+obj[key]+"<br />";
	}
	return r;
}


Object.size = function(obj)
{
	return Object.keys(obj).length;
};

export function ForceRedraw(el)
{
	var display = el.getStyle("display");
	el.setStyle({display:"none"}).offsetHeight;
	el.setStyle({display:display});
	return el;
}

export var ResetChosenFocus = function(/* newfocus */) {
	// var _newfocus = newfocus || false;
	setTimeout(function() {
		$$(".chosen-container-active").each(function(c) {
			c.previous("select").Chosen.close_field();
		});
	}, 20);
};


export var CookieVar = function(key, value) {
	if (typeof value !== "undefined") {
		document.cookie = "SB_cache/" + key + "=" + escape(value) + "; path=/";
	} else {
		return Cookie.get("SB_cache/" + key);
	}
};
export var Cookie = {
	set: function(key, value) {
		//		var expires = new Date();
		//		expires.setTime(expires.getTime()+1000*60*60*24*365)
		document.cookie = key + "=" + escape(value); //+'; expires='+expires+'; path=/';
	},

	get: function(key) {
		var cookie = key + "=";
		var array = document.cookie.split(";");
		for (var i = 0; i < array.length; i++) {
			var c = array[i].replace(/^\s+|\s+$/g, "");
			if (c.indexOf(cookie) == 0) {
				var result = c.substring(cookie.length, c.length);
				return unescape(result);
			}
		}
		return null;
	},
};


export function __substr_count (haystack, needle, offset, length) {
	// Returns the number of times a substring occurs in the string  
	// 
	// version: 1109.2015
	// discuss at: http://phpjs.org/functions/substr_count    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// +   bugfixed by: Onno Marsman
	// *     example 1: substr_count('Kevin van Zonneveld', 'e');
	// *     returns 1: 3
	// *     example 2: substr_count('Kevin van Zonneveld', 'K', 1);    // *     returns 2: 0
	// *     example 3: substr_count('Kevin van Zonneveld', 'Z', 0, 10);
	// *     returns 3: false
	var /* pos = 0, */
		cnt = 0; 
	haystack += '';
	needle += '';
	if (isNaN(offset)) {
		offset = 0;    }
	if (isNaN(length)) {
		length = 0;
	}
	offset--; 
	while ((offset = haystack.indexOf(needle, offset + 1)) != -1) {
		if (length > 0 && offset + needle.length > length) {
			return false;
		} else {
			cnt++;
		}
	}
 
	return cnt;}
	
export function __number_format (number, decimals, dec_point, thousands_sep) {
	// Formats a number with grouped thousands  
	// 
	// version: 1109.2015
	// discuss at: http://phpjs.org/functions/number_format    // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
	// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// +     bugfix by: Michael White (http://getsprink.com)
	// +     bugfix by: Benjamin Lupton
	// +     bugfix by: Allan Jensen (http://www.winternet.no)    // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
	// +     bugfix by: Howard Yeend
	// +    revised by: Luke Smith (http://lucassmith.name)
	// +     bugfix by: Diogo Resende
	// +     bugfix by: Rival    // +      input by: Kheang Hok Chin (http://www.distantia.ca/)
	// +   improved by: davook
	// +   improved by: Brett Zamir (http://brett-zamir.me)
	// +      input by: Jay Klehr
	// +   improved by: Brett Zamir (http://brett-zamir.me)    // +      input by: Amir Habibi (http://www.residence-mixte.com/)
	// +     bugfix by: Brett Zamir (http://brett-zamir.me)
	// +   improved by: Theriault
	// +      input by: Amirouche
	// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)    // *     example 1: number_format(1234.56);
	// *     returns 1: '1,235'
	// *     example 2: number_format(1234.56, 2, ',', ' ');
	// *     returns 2: '1 234,56'
	// *     example 3: number_format(1234.5678, 2, '.', '');    // *     returns 3: '1234.57'
	// *     example 4: number_format(67, 2, ',', '.');
	// *     returns 4: '67,00'
	// *     example 5: number_format(1000);
	// *     returns 5: '1,000'    // *     example 6: number_format(67.311, 2);
	// *     returns 6: '67.31'
	// *     example 7: number_format(1000.55, 1);
	// *     returns 7: '1,000.6'
	// *     example 8: number_format(67000, 5, ',', '.');    // *     returns 8: '67.000,00000'
	// *     example 9: number_format(0.9, 0);
	// *     returns 9: '1'
	// *    example 10: number_format('1.20', 2);
	// *    returns 10: '1.20'    // *    example 11: number_format('1.20', 4);
	// *    returns 11: '1.2000'
	// *    example 12: number_format('1.2000', 3);
	// *    returns 12: '1.200'
	// *    example 13: number_format('1 000,50', 2, '.', ' ');    // *    returns 13: '100 050.00'
	// Strip all characters but numerical ones.
	number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec);            return '' + Math.round(n * k) / k;
		};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');    }
	return s.join(dec);
}

export function filter_object(obj, func) {
	var result = {}, key;
	for (key in obj) {
		if (obj.hasOwnProperty(key) && func(obj[key])) {
			result[key] = obj[key];
		}
	}
	return result;
}


export var decodeEntities = (function () {
	// this prevents any overhead from creating the object each time
	var element = document.createElement('div');

	function decodeHTMLEntities(str) {
		if (str && typeof str === 'string') {
			// strip script/html tags
			str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
			str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
			element.innerHTML = str;
			str = element.textContent;
			element.textContent = '';
		}

		return str;
	}

	return decodeHTMLEntities;
})();

// Funktioner til formattering af datoer og klokkeslet
import parseISO from "date-fns/parseISO";
import dateFormatOrig from 'date-fns/format';
import dateIsValid from 'date-fns/isValid';
// import dateParse from 'date-fns/parse';
import da from 'date-fns/locale/da';
export function dateFormat(date, formatStr, options = {}) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	return dateIsValid(date) ? dateFormatOrig(date, formatStr, Object.assign({ locale: da }, options)) : false;
}
import dateDistanceInWords from "date-fns/formatDistanceStrict";
export function dateRelativeFormat(date, options = {}, compareDate = new Date) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	return dateDistanceInWords(date, compareDate, Object.assign({ addSuffix: true, locale: da }, options))
}
import dateFormatRelative from "date-fns/formatRelative";
export function timeRelativeFormat(date, options = {}, compareDate = new Date) {
	try {
		if (typeof date === "string") {
			date = parseISO(date);
		}
		let origFormatRelative = da.formatRelative;
		var formatRelativeLocale = {
			lastWeek: "'sidste' eeee pp",
			yesterday: "'i går' pp",
			today: "pp",
			tomorrow: "'i morgen' pp",
			nextWeek: "'på' eeee pp",
			other: 'PP pp'
		};
		let custom_locale = {
			...da,
			formatRelative: token => formatRelativeLocale[token],
			// formatRelative: token => {
			// 	if (token == "other") {
			// 		return 'PP pp';
			// 		// return 'yyyy-LL-dd HH:ii:ss';
			// 	}
			// 	return origFormatRelative(token);
			// },
		  };
		return dateFormatRelative(date, compareDate, Object.assign({ addSuffix: true, locale: custom_locale, weekStartsOn: 1 }, options))
	} catch (err) {
		return "Ukendt dato";
	}
}


import { SB_MessageStack } from "./message_stack.es6";
export const copy_to_clipboard = str => {
	const el = document.createElement('textarea');  // Create a <textarea> element
	el.value = str;                                 // Set its value to the string that you want copied
	el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
	el.style.position = 'absolute';                 
	el.style.left = '-9999px';                      // Move outside the screen to make it invisible
	el.style.opacity = '0';							// Set opacity to 0 to make it completely invisible
	document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
	const selected =            
		document.getSelection().rangeCount > 0        // Check if there is any content selected previously
			? document.getSelection().getRangeAt(0)     // Store selection if found
			: false;                                    // Mark as false to know no selection existed before
	el.select();                                    // Select the <textarea> content
	document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
	document.body.removeChild(el);                  // Remove the <textarea> element
	if (selected) {                                 // If a selection existed before copying
		document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
		document.getSelection().addRange(selected);   // Restore the original selection
	}
	SB_MessageStack.Add("clipboard success", "Link kopieret", SB_Timestamp(), 1500);
};
export const copy_link_to_clipboard = (str, href) => {
	// // Med inspiration fra https://stackoverflow.com/a/34192073
	// var activeSheets = Array.prototype.slice.call(document.styleSheets)
	// 	.filter(function (sheet) {
	// 		return !sheet.disabled
	// 	});

	const a = document.createElement('a');
	a.innerText = str;
	a.href = href;
	a.style.textDecoration = "underline";
	a.style.cursor = "pointer";
	// a.style.position = 'absolute';                 
	// a.style.left = '-9999px';                      // Move outside the screen to make it invisible
	// a.style.opacity = '0';							// Set opacity to 0 to make it completely invisible
	document.body.appendChild(a);                  // Append the <a> element to the HTML document

	const selected =            
		document.getSelection().rangeCount > 0        // Check if there is any content selected previously
			? document.getSelection().getRangeAt(0)     // Store selection if found
			: false;                                    // Mark as false to know no selection existed before

	window.getSelection().removeAllRanges();
	let range = document.createRange();
	range.selectNode(a);
	window.getSelection().addRange(range);		
	document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
	// // [5.2]
	// for (var i = 0; i < activeSheets.length; i++) {
	// 	activeSheets[i].disabled = true;
	// }

	// // [5.3]
	// document.execCommand('copy')

	// // [5.4]
	// for (var i = 0; i < activeSheets.length; i++) {
	// 	activeSheets[i].disabled = false;
	// }

	document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
	document.body.removeChild(a);                  // Remove the <a> element
		
	if (selected) {                                 // If a selection existed before copying
		document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
		document.getSelection().addRange(selected);   // Restore the original selection
	}
	SB_MessageStack.Add("clipboard success", "Link kopieret", SB_Timestamp(), 1500);
}

export function insert_at_cursor(myField, myValue, selectAfterwards = false) {
	if (!myField || !myValue) {
		return;
	}
    //IE support
    if (document.selection) {
        myField.focus();
        let sel = document.selection.createRange();
        sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
        myField.selectionStart = selectAfterwards ? startPos : startPos + myValue.length;
        myField.selectionEnd = startPos + myValue.length;
    } else {
		let startPos = myField.value.length;
		myField.value = myField.value + myValue;
		myField.selectionStart = startPos;
		if (selectAfterwards) {
			myField.selectionEnd = startPos + myValue.length;
		}
    }
}

export function insert_around_cursor(myField, before, after, selectAfterwards = true) {
	if (!myField || !before || !after) {
		return;
	}
    //IE support
    if (document.selection) {
        myField.focus();
        let sel = document.selection.createRange();
        sel.text = before + sel.text + after;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
			+ before
			+ myField.value.substring(startPos, endPos)
			+ after
            + myField.value.substring(endPos, myField.value.length);
        myField.selectionStart = selectAfterwards ? startPos : startPos + `${before}${after}`.length;
        myField.selectionEnd = endPos + `${before}${after}`.length;
    } else {
		let startPos = myField.value.length;
		myField.value = myField.value + `${before}${after}`;
		myField.selectionStart = startPos;
		if (selectAfterwards) {
			myField.selectionEnd = startPos + `${before}${after}`.length;
		}
    }
}

export const intervalHelper = (selector_element, start_date_element, end_date_element, callback) => {
	var sel = selector_element;
	var idx = sel.selectedIndex;
	
	if (idx != 0) {
		var start_date = $(sel.options[idx]).readAttribute("start_date");
		var end_date = $(sel.options[idx]).readAttribute("end_date");

		start_date_element.update(start_date);
		end_date_element.update(end_date);
		
		
		setTimeout(function() {
			SB_Input(sel).update().blur();
			if (typeof callback == "function") {
				callback(start_date, end_date);
			}
		},200);
	}
}

// For at kunne forberede nogle oversættelser til også at fungere i javascript.
// Kan senere måske bruge https://github.com/alexei/sprintf.js
export function __(string) {
	return string;
}